// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Notification_DropdownMenu__lXtlt {
  left: -30rem !important;
  border-radius: 7px !important;
  max-height: 350px;
  overflow-y: auto;
}

.Notification_DropdownMenuSpan__p2ZTP {
  color: #9537ff;
  text-align: center;
  font-size: 28px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.Notification_DropdownMenuFooter__3LBqP {
  text-align: center;
  font-size: 16px;
}
.Notification_DropdownMenuFooter__3LBqP .dropdown-item:active {
  background-color: var(--bs-dropdown-link-hover-bg);
}
.Notification_DropdownItem__g7gah {
  text-decoration: none;
}
.Notification_DropdownItem__g7gah:hover {
  text-decoration: underline;
}

.Notification_DropdownItem__g7gah:active {
  background-color: var(--bs-dropdown-link-hover-bg) !important;
  color: var(--bs-dropdown-link-color) !important;
}

.Notification_navtabs__U9g1m .nav-link {
  background-color: #ffffff !important;
  color: rgba(149, 55, 255, 1) !important;
  padding: 8px !important;
  font-size: 14px;
  border: 1px solid rgba(222, 223, 228, 1) !important;
}

.Notification_navtabs__U9g1m .nav-link.active {
  background-color: rgba(149, 55, 255, 1) !important;
  color: #ffffff !important;
}

@media screen and (max-width: 576px) {
  .Notification_DropdownMenu__lXtlt {
    left: -12rem !important;
    right: -6rem !important;
    max-width: 100vw;
    max-height: 85vh;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Notification.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,6BAA6B;EAC7B,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,kDAAkD;AACpD;AACA;EACE,qBAAqB;AACvB;AACA;EACE,0BAA0B;AAC5B;;AAEA;EACE,6DAA6D;EAC7D,+CAA+C;AACjD;;AAEA;EACE,oCAAoC;EACpC,uCAAuC;EACvC,uBAAuB;EACvB,eAAe;EACf,mDAAmD;AACrD;;AAEA;EACE,kDAAkD;EAClD,yBAAyB;AAC3B;;AAEA;EACE;IACE,uBAAuB;IACvB,uBAAuB;IACvB,gBAAgB;IAChB,gBAAgB;EAClB;AACF","sourcesContent":[".DropdownMenu {\n  left: -30rem !important;\n  border-radius: 7px !important;\n  max-height: 350px;\n  overflow-y: auto;\n}\n\n.DropdownMenuSpan {\n  color: #9537ff;\n  text-align: center;\n  font-size: 28px;\n  margin-bottom: 10px;\n  margin-top: 10px;\n}\n\n.DropdownMenuFooter {\n  text-align: center;\n  font-size: 16px;\n}\n.DropdownMenuFooter :global(.dropdown-item):active {\n  background-color: var(--bs-dropdown-link-hover-bg);\n}\n.DropdownItem {\n  text-decoration: none;\n}\n.DropdownItem:hover {\n  text-decoration: underline;\n}\n\n.DropdownItem:active {\n  background-color: var(--bs-dropdown-link-hover-bg) !important;\n  color: var(--bs-dropdown-link-color) !important;\n}\n\n.navtabs :global(.nav-link) {\n  background-color: #ffffff !important;\n  color: rgba(149, 55, 255, 1) !important;\n  padding: 8px !important;\n  font-size: 14px;\n  border: 1px solid rgba(222, 223, 228, 1) !important;\n}\n\n.navtabs :global(.nav-link.active) {\n  background-color: rgba(149, 55, 255, 1) !important;\n  color: #ffffff !important;\n}\n\n@media screen and (max-width: 576px) {\n  .DropdownMenu {\n    left: -12rem !important;\n    right: -6rem !important;\n    max-width: 100vw;\n    max-height: 85vh;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DropdownMenu": `Notification_DropdownMenu__lXtlt`,
	"DropdownMenuSpan": `Notification_DropdownMenuSpan__p2ZTP`,
	"DropdownMenuFooter": `Notification_DropdownMenuFooter__3LBqP`,
	"DropdownItem": `Notification_DropdownItem__g7gah`,
	"navtabs": `Notification_navtabs__U9g1m`
};
export default ___CSS_LOADER_EXPORT___;
