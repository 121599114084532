import { faChevronDown, faChevronUp, faCloudArrowUp, faGear, faShop, faTicket, faUniversity, faExchange, faShoppingBag, faUpload, faPenClip, faStar, faShippingFast } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import featuredIcon from "../../assets/svg/featuredIcon.svg";

import dashboard from "./../../assets/svg/dashboard.svg";
import activeDashboard from "./../../assets/svg/activeDashboard.svg";
import inventory from "./../../assets/svg/Inventory.svg";
import activeInventory from "./../../assets/svg/activeInvetory.svg";
import sales from "./../../assets/svg/salesIcon.svg";
import activeSales from "./../../assets/svg/activeSales.svg";
import SalesHistory from "../../Pages/SalesHistory";
import Refunds from "../../Pages/Refunds/Refunds";
import vendor from "./../../assets/svg/vendor.svg";
import activeVendor from "./../../assets/svg/activeVendor.svg";
import customer from "./../../assets/svg/Customer.svg";
import activeCustomer from "./../../assets/svg/activeCustomer.svg";
import reports from "./../../assets/svg/Reports.svg";
import activeReports from "./../../assets/svg/activeReports.svg";
import expense from "./../../assets/svg/expenseIcon.svg";
import activeExpense from "./../../assets/svg/activeExpense.svg";
import profile from "./../../assets/svg/profile.svg";
import activeProfile from "./../../assets/svg/activeProfile.svg";
import settings from "./../../assets/svg/settings.svg";
import activeSettings from "./../../assets/svg/activeSetting.svg";
import addIcon from "./../../assets/svg/add-icon.svg";
import activeAddIcon from "./../../assets/svg/activeAddIcon.svg";
import list from "./../../assets/svg/list.svg";
import activeList from "./../../assets/svg/activeList.svg";
import salesIcon from "./../../assets/svg/sales-icon.svg";
import activeSalesIcon from "./../../assets/svg/activeSalesIcon.svg";
import history from "./../../assets/svg/history.svg";
import activeHistory from "./../../assets/svg/activeHistory.svg";
import vendorList from "./../../assets/svg/vendor-list.svg";
import activeVendorList from "./../../assets/svg/activeVendorList.svg";
import addVendor from "./../../assets/svg/add-vendor.svg";
import activeAddVendor from "./../../assets/svg/activeAddVendor.svg";
import star from "./../../assets/svg/star.svg";
import activeStar from "./../../assets/svg/activeStar.svg";
import locationList from "./../../assets/svg/location-list.svg";
import activeLocationList from "./../../assets/svg/activeLocationList.svg";
import resetPassword from "./../../assets/svg/lock-icon.svg";
import activeResetPassword from "./../../assets/svg/activeLock.svg";
import purchaseOrder from "./../../assets/svg/purchseOrder.svg";
import InactivePurchaseOrder from "./../../assets/svg/inactivePurchaseOrder.svg";
import listings from "./../../assets/svg/listings.svg";
import activeListings from "./../../assets/svg/activeListings.svg";
import { faImages } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";

import { useEffect, useState } from "react"; // Import useEffect and useState from react
import Dashboard from "../../Pages/Dashboard";
import InventoryList from "../../Pages/InventoryList";
import InventoryForm from "../InventoryForms/InventoryForm";
import DataImport from "../../Pages/DataImport";

import ResetPassword from "../../Pages/ResetPassword";
import AddLocation from "../../Pages/AddLocation";
import LocationList from "../../Pages/LocationList";
import BankList from "../../Pages/Bank/BankList";
import Settings from "../../Pages/Settings/Settings";
import DashboardLayout from "../../Layout/DashboardLayout";
import Sales from "../../Pages/Sales/Sales";
import PurchaseOrder from "../../Pages/PurchaseOrder/PurchaseOrder";
import Listings from "../../Pages/Listings";
import Reservations from "../../Pages/Reservations/Reservations";
import Customize from "../../Pages/Customize/Customize";
import Vendor from "../../Pages/Vendor/Vendor";
import AddVendor from "../../Pages/Vendor/AddVendor";
import EditVendor from "../../Pages/Vendor/EditVendor";
import TradeInCustomers from "../../Pages/TradeIn/TradeInCustomers";
import TradeInProducts from "../../Pages/TradeIn/TradeInProducts";
import TradeInInvoice from "../../Pages/TradeIn/TradeInInvoice";
import Customer from "../../Pages/Customer/Customer";
import SecondaryUser from "../../Pages/SecondaryUser/SecondaryUser";
import Reports from "../../Pages/Reports";
import Expenses from "../../Pages/Expenses/Expenses";
import Subscriptions from "../../Pages/Subscriptions";
import InvoicePaymentSalesInvoice from "../../Pages/SalesHistory/InvoicePaymentSales/InvoicePaymentSalesInvoice";
import InvoiceLayout from "../../Pages/Sales/Invoice/InvoiceLayout";
import InvoiceView from "../../Pages/invoiceView";
import PaymentForm from "../../Pages/PurchaseOrder/PaymentForm";
import Payments from "../../Pages/PurchaseOrder/Payments";
import MyCampaign from "../../Pages/MyCampaign";
import PlansCards from "../../Components/plans/PlansCard";
import SystemSetting from "../../Pages/Settings/SystemSetting";
import RefundsInvoice from "../../Pages/Refunds/RefundsInvoice";
import DeliveryForYouOrders from "../../Pages/DeliveryForYouOrders/DeliveryForYouOrders";
import DeliveryForYouOrderInvoicePage from "../../Pages/DeliveryForYouOrders/DeliveryForYouOrderInvoicePage";
const SideNavData = [
  {
    identifier: "Dashboard",
    title: "Dashboard",
    path: "/",
    icon: <img src={dashboard} alt="dashboard" />,
    activeIcon: <img src={activeDashboard} alt="dashboard" />,
    Component: Dashboard,
  },
  {
    identifier: "Inventory",
    title: "Inventory",
    path: "/inventory",
    icon: <img src={inventory} alt="inventory" />,
    activeIcon: <img src={activeInventory} alt="inventory" />,
    iconClose: <FontAwesomeIcon icon={faChevronDown} />,
    iconOpen: <FontAwesomeIcon icon={faChevronUp} />,
    isSubNavExists: true,
    Component: InventoryList,
    subNav: [
      {
        subnavigation: true,
        alwaysGenerateRoute: true,
        identifier: "AddInventory",
        title: "Add Inventory",
        path: "/inventory/add",
        icon: <img width="20px" height="20px" src={addIcon} alt="addIcon" />,
        activeIcon: <img width="20px" height="20px" src={activeAddIcon} alt="addIcon" />,
        Component: InventoryForm,
      },
      {
        navigation: false,
        alwaysGenerateRoute: true,
        subnavigation: true,
        identifier: "AddInventory",
        title: "Add Inventory",
        path: "/inventory/:mode/*",
        icon: <img width="20px" height="20px" src={addIcon} alt="addIcon" />,
        activeIcon: <img width="20px" height="20px" src={activeAddIcon} alt="addIcon" />,
        Component: InventoryForm,
      },
      {
        subnavigation: true,
        alwaysGenerateRoute: true,
        identifier: "MyList",
        title: "My List",
        path: "/inventory",
        icon: <img width="20px" height="20px" src={list} alt="list" />,
        activeIcon: <img width="20px" height="20px" src={activeList} alt="list" />,
        isIndex: true,
        Component: InventoryList,
      },

      {
        subnavigation: true,
        alwaysGenerateRoute: true,
        identifier: "DataImport",
        title: "Data Import",
        path: "/inventory/dataimport",
        icon: <FontAwesomeIcon style={{ width: "20px", height: "20px" }} icon={faCloudArrowUp} />,
        activeIcon: <FontAwesomeIcon style={{ width: "20px", height: "20px" }} icon={faCloudArrowUp} />,
        Component: DataImport,
      },
    ],
  },
  {
    identifier: "Sales",
    title: "Sales",
    path: "/sales",
    icon: <img src={sales} alt="sales" />,
    activeIcon: <img src={activeSales} alt="sales" />,
    iconClose: <FontAwesomeIcon icon={faChevronDown} />,
    iconOpen: <FontAwesomeIcon icon={faChevronUp} />,
    isSubNavExists: true,
    Component: Sales,

    subNav: [
      {
        subnavigation: true,
        alwaysGenerateRoute: true,
        identifier: "SalesPage",
        title: "Sale",
        path: "/sales",
        icon: <img width="20px" height="20px" src={salesIcon} alt="salesIcon" />,
        activeIcon: <img width="20px" height="20px" src={activeSalesIcon} alt="salesIcon" />,
        Component: Sales,
      },
      {
        subnavigation: true,
        alwaysGenerateRoute: true,
        identifier: "SaleHistory",
        title: "Sale History",
        path: "/sales/sales-history",
        icon: <img width="20px" height="20px" src={history} alt="history" />,
        activeIcon: <img width="20px" height="20px" src={activeHistory} alt="history" />,
        Component: SalesHistory,
      },
      {
        subnavigation: true,
        alwaysGenerateRoute: true,
        identifier: "RefundHistory",
        title: "Refund History",
        path: "/sales/refunds-history",
        icon: <img width="20px" height="20px" src={history} alt="history" />,
        activeIcon: <img width="20px" height="20px" src={activeHistory} alt="history" />,
        Component: Refunds,
      },
      {
        subnavigation: true,
        navigation: false,
        alwaysGenerateRoute: true,
        identifier: "RefundHistory/id",
        title: "Refund History",
        path: "/sales/refunds-history/:id",
        icon: <img width="20px" height="20px" src={history} alt="history" />,
        activeIcon: <img width="20px" height="20px" src={activeHistory} alt="history" />,
        Component: RefundsInvoice,
      },
      {
        subnavigation: true,
        navigation: false,
        alwaysGenerateRoute: true,
        identifier: "invoice/id",
        title: "Refund History",
        path: "/invoice/:id",
        icon: <img width="20px" height="20px" src={history} alt="history" />,
        activeIcon: <img width="20px" height="20px" src={activeHistory} alt="history" />,
        Component: InvoiceLayout,
      },
      {
        subnavigation: true,
        alwaysGenerateRoute: true,
        navigation: false,
        identifier: "payment-invoice",
        title: "Refund History",
        path: "/invoice-view/:id",
        icon: <img width="20px" height="20px" src={history} alt="history" />,
        activeIcon: <img width="20px" height="20px" src={activeHistory} alt="history" />,
        Component: InvoiceView,
      },
      {
        subnavigation: true,
        alwaysGenerateRoute: true,
        navigation: false,
        identifier: "salesInvoice",
        title: "Refund History",
        path: "/invoice-payments-sales/:id",
        icon: <img width="20px" height="20px" src={history} alt="history" />,
        activeIcon: <img width="20px" height="20px" src={activeHistory} alt="history" />,
        Component: InvoicePaymentSalesInvoice,
      },
    ],
  },
  {
    identifier: "Marketplace",
    title: "Marketplace",
    path: "/listings",
    icon: <FontAwesomeIcon icon={faShop} />,
    activeIcon: (
      <span style={{ color: "#774ced" }}>
        <FontAwesomeIcon icon={faShop} />
      </span>
    ),
    iconClose: <FontAwesomeIcon icon={faChevronDown} />,
    iconOpen: <FontAwesomeIcon icon={faChevronUp} />,
    isSubNavExists: true,

    Component: Listings,
    subNav: [
      {
        subnavigation: true,
        identifier: "Listings",
        alwaysGenerateRoute: true,
        title: "Listings",
        path: "/listings",
        icon: <img width="20px" height="20px" src={listings} alt="list" />,
        activeIcon: <img width="20px" height="20px" src={activeListings} alt="list" />,
        Component: Listings,
      },

      {
        subnavigation: true,
        alwaysGenerateRoute: true,
        identifier: "Reservations",
        title: "Reservations",
        path: "/listings/reservations",
        icon: <FontAwesomeIcon width="20px" height="20px" style={{ marginRight: "0 !important" }} icon={faTicket} />,
        activeIcon: <FontAwesomeIcon width="20px" height="20px" icon={faTicket} />,
        Component: Reservations,
      },

      {
        subnavigation: true,
        alwaysGenerateRoute: true,
        identifier: "ThemeStore",
        title: "Theme Store",
        path: "/listings/themes",
        icon: <FontAwesomeIcon style={{ marginRight: "0 !important" }} icon={faImages} />,
        activeIcon: <FontAwesomeIcon icon={faImages} />,
        Component: Customize,
      },
      {
        subnavigation: true,
        identifier: "Orders",
        title: "Orders",
        path: "/listings/orders",
        icon: <FontAwesomeIcon style={{ marginRight: "0 !important" }} icon={faShippingFast} />,
        activeIcon: <FontAwesomeIcon icon={faShippingFast} />,
        Component: DeliveryForYouOrders,
      },
      {
        subnavigation: true,
        alwaysGenerateRoute: true,
        navigation: false,
        identifier: "Orders",
        title: "Orders",
        path: `/listings/orders/:invoiceId/invoice`,
        icon: <FontAwesomeIcon style={{ marginRight: "0 !important" }} icon={faShippingFast} />,
        activeIcon: <FontAwesomeIcon icon={faShippingFast} />,
        Component: DeliveryForYouOrderInvoicePage,
      },
      {
        subnavigation: true,
        alwaysGenerateRoute: true,
        navigation: false,
        identifier: "salesInvoice",
        title: "Refund History",
        path: "/invoice-payments-sales/:id",
        icon: <img width="20px" height="20px" src={history} alt="history" />,
        activeIcon: <img width="20px" height="20px" src={activeHistory} alt="history" />,
        Component: InvoicePaymentSalesInvoice,
      },
    ],
  },

  {
    identifier: "Vendor",
    title: "Supplier",
    path: "/supplier",
    icon: <img src={vendor} alt="supplier" />,
    activeIcon: <img src={activeVendor} alt="supplier" />,
    iconClose: <FontAwesomeIcon icon={faChevronDown} />,
    iconOpen: <FontAwesomeIcon icon={faChevronUp} />,
    isSubNavExists: true,

    Component: Vendor,
    subNav: [
      {
        subnavigation: true,
        alwaysGenerateRoute: true,
        identifier: "Vendorlist",
        title: "Supplier",
        path: "/supplier",
        icon: <img width="20px" height="20px" src={vendorList} alt="supplierList" />,
        activeIcon: <img width="20px" height="20px" src={activeVendorList} alt="supplierList" />,
        Component: Vendor,
      },
      {
        subnavigation: true,
        alwaysGenerateRoute: true,
        identifier: "AddVendor",
        title: "Add Supplier",
        path: "/supplier/add",
        icon: <img width="20px" height="20px" src={addVendor} alt="addSupplier" />,
        activeIcon: <img width="20px" height="20px" src={activeAddVendor} alt="addSupplier" />,
        Component: AddVendor,
      },
      {
        subnavigation: true,
        alwaysGenerateRoute: true,
        navigation: false,
        identifier: "EditVendor",
        title: "Supplier List",
        path: "/supplier/edit/:id",
        icon: <img width="20px" height="20px" src={addVendor} alt="addSupplier" />,
        activeIcon: <img width="20px" height="20px" src={activeAddVendor} alt="addSupplier" />,
        Component: EditVendor,
      },
    ],
  },
  {
    identifier: "PurchaseOrder",
    title: "Purchase Order",
    path: "/purchase-order",
    icon: <img width="20px" height="20px" src={InactivePurchaseOrder} alt="purchaseOrder" />,
    activeIcon: <img width="20px" height="20px" src={purchaseOrder} alt="purchaseOrder" />,
    isSubNavExists: false,
    Component: PurchaseOrder,
    subNav: [
      {
        subnavigation: true,
        navigation: false,
        alwaysGenerateRoute: true,
        identifier: "PurchaseOrder",
        title: "purchaseOrderEdit",
        path: "/purchase-order",
        icon: <img width="20px" height="20px" src={addVendor} alt="addVendor" />,
        activeIcon: <img width="20px" height="20px" src={activeAddVendor} alt="addVendor" />,
        Component: PurchaseOrder,
      },
      {
        subnavigation: true,
        navigation: false,
        identifier: "PurchaseOrder",
        title: "purchaseOrderEdit",
        path: "/purchase-order",
        icon: <img width="20px" height="20px" src={addVendor} alt="addVendor" />,
        activeIcon: <img width="20px" height="20px" src={activeAddVendor} alt="addVendor" />,
        Component: PurchaseOrder,
      },
      {
        subnavigation: true,
        alwaysGenerateRoute: true,
        navigation: false,
        identifier: "purchaseOrderPayment",
        title: "purchaseOrderPayment",
        path: "purchase-order/:purchase_id/payment",
        icon: <img width="20px" height="20px" src={addVendor} alt="addVendor" />,
        activeIcon: <img width="20px" height="20px" src={activeAddVendor} alt="addVendor" />,
        Component: Payments,
      },
    ],
  },

  {
    subnavigation: true,
    navigation: false,
    identifier: "PurchaseOrder",
    title: "purchaseOrderEdit",
    path: "/purchase-order",
    icon: <img width="20px" height="20px" src={addVendor} alt="addVendor" />,
    activeIcon: <img width="20px" height="20px" src={activeAddVendor} alt="addVendor" />,
    Component: PurchaseOrder,
    subNav: [
      {
        subnavigation: true,
        alwaysGenerateRoute: true,
        navigation: false,
        identifier: "purchaseOrderPayment",
        title: "purchaseOrderPayment",
        path: "purchase-order/:purchase_id/payment",
        icon: <img width="20px" height="20px" src={addVendor} alt="addVendor" />,
        activeIcon: <img width="20px" height="20px" src={activeAddVendor} alt="addVendor" />,
        Component: Payments,
      },
    ],
  },

  {
    subnavigation: true,
    navigation: false,
    identifier: "PurchaseOrder",
    title: "purchaseOrderEdit",
    path: "/purchase-order",
    icon: <img width="20px" height="20px" src={addVendor} alt="addVendor" />,
    activeIcon: <img width="20px" height="20px" src={activeAddVendor} alt="addVendor" />,
    Component: PurchaseOrder,
    subNav: [
      {
        subnavigation: true,
        alwaysGenerateRoute: true,
        navigation: false,
        identifier: "purchaseOrderPayment",
        title: "purchaseOrderPayment",
        path: "purchase-order/:purchase_id/payment",
        icon: <img width="20px" height="20px" src={addVendor} alt="addVendor" />,
        activeIcon: <img width="20px" height="20px" src={activeAddVendor} alt="addVendor" />,
        Component: Payments,
      },
    ],
  },

  {
    identifier: "Customer",
    title: "Customer",
    path: "/customer",
    icon: <img width="20px" height="20px" src={customer} alt="customer" />,
    activeIcon: <img width="20px" height="20px" src={activeCustomer} alt="customer" />,
    Component: Customer,
  },

  {
    subnavigation: true,
    navigation: false,
    identifier: "editCutomer",
    title: "EditCustomer",
    path: "/edit/:id",
    icon: <img width="20px" height="20px" src={addVendor} alt="addVendor" />,
    activeIcon: <img width="20px" height="20px" src={activeAddVendor} alt="addVendor" />,
    Component: EditVendor,
  },
  {
    identifier: "TradeIn",
    title: "Trade-in",
    path: "/trade-in",
    icon: <FontAwesomeIcon icon={faExchange} />,
    activeIcon: <FontAwesomeIcon width="20px" height="20px" icon={faExchange} />,
    iconClose: <FontAwesomeIcon icon={faChevronDown} />,
    iconOpen: <FontAwesomeIcon icon={faChevronUp} />,
    isSubNavExists: true,
    Component: TradeInCustomers,

    subNav: [
      {
        subnavigation: true,
        alwaysGenerateRoute: true,
        identifier: "TradeInCustomer",
        title: "Trade-in Customer",
        path: "/trade-in",
        icon: <img width="20px" height="20px" src={addVendor} alt="addVendor" />,
        activeIcon: <img width="20px" height="20px" src={activeAddVendor} alt="addVendor" />,
        Component: TradeInCustomers,
      },
      {
        subnavigation: true,
        identifier: "TradeInProduct",
        alwaysGenerateRoute: true,
        title: "Trade-in Product",
        path: "/trade-in/products",
        icon: <FontAwesomeIcon icon={faShoppingBag} />,
        activeIcon: <FontAwesomeIcon width="20px" height="20px" icon={faShoppingBag} />,
        Component: TradeInProducts,
      },

      {
        subnavigation: true,
        alwaysGenerateRoute: true,
        navigation: false,
        identifier: "TradeInProduct",
        title: "Trade-in ",
        path: "/trade-in/:id",
        icon: <FontAwesomeIcon icon={faShoppingBag} />,
        activeIcon: <FontAwesomeIcon width="20px" height="20px" icon={faShoppingBag} />,
        Component: TradeInInvoice,
      },
    ],
  },

  {
    identifier: "Reports",
    title: "Reports",
    path: "/reports",
    icon: <img width="20px" height="20px" src={reports} alt="reports" />,
    activeIcon: <img width="20px" height="20px" src={activeReports} alt="reports" />,
    Component: Reports,
  },
  {
    identifier: "MyExpenses",
    title: "My Expenses",
    path: "/expenses",
    icon: <img width="20px" height="20px" src={expense} alt="expense" />,
    activeIcon: <img width="20px" height="20px" src={activeExpense} alt="expense" />,
    Component: Expenses,
  },
  {
    identifier: "Marketing",
    title: "Marketing",
    path: "/marketing",
    icon: <img width="20px" height="20px" src={expense} alt="expense" />,
    activeIcon: <img width="20px" height="20px" src={activeExpense} alt="expense" />,
    Component: MyCampaign,
    isComing: true,
  },
  // {
  //   identifier: "MyMarketing",
  //   title: "My Marketing",
  //   path: "/marketing",
  //   icon: <img width="20px" height="20px" src={marketing} alt="marketing" />,
  //   activeIcon: (
  //     <img width="20px" height="20px" src={activeMarketing} alt="marketing" />
  //   ),
  // },
  {
    identifier: "Employees",
    title: "Employees",
    path: "/employees",
    icon: <img width="20px" height="20px" src={profile} alt="user" />,
    activeIcon: <img width="20px" height="20px" src={activeProfile} alt="user" />,
    Component: SecondaryUser,
  },
  {
    identifier: "Settings",
    title: "Settings",
    path: "/settings/locations",
    icon: <img src={settings} alt="settings" />,
    activeIcon: <img src={activeSettings} alt="settings" />,
    iconClose: <FontAwesomeIcon icon={faChevronDown} />,
    iconOpen: <FontAwesomeIcon icon={faChevronUp} />,
    isSubNavExists: true,
    Component: Settings,

    subNav: [
      {
        subnavigation: true,
        alwaysGenerateRoute: true,
        identifier: "ResetPassword",
        title: "Reset Password",
        path: "/settings/reset-password",
        icon: <img width="29px" height="29px" src={resetPassword} alt="lock" />,
        activeIcon: <img width="30px" height="30px" src={activeResetPassword} alt="lock" />,
        Component: ResetPassword,
      },
      {
        subnavigation: true,
        alwaysGenerateRoute: true,
        identifier: "LocationList",
        title: "Locations",
        path: "/settings/locations",
        icon: <img className="me-2" width="20px" height="20px" src={locationList} alt="locationList" />,
        activeIcon: <img className="me-2" width="20px" height="20px" src={activeLocationList} alt="locationList" />,
        Component: LocationList,
      },
      {
        subnavigation: true,
        navigation: false,
        alwaysGenerateRoute: true,
        identifier: "LocationList",
        title: "Locations",
        path: "/settings/add-location",
        icon: <img className="me-2" width="20px" height="20px" src={locationList} alt="locationList" />,
        activeIcon: <img className="me-2" width="20px" height="20px" src={activeLocationList} alt="locationList" />,
        Component: AddLocation,
      },
      {
        subnavigation: true,
        alwaysGenerateRoute: true,
        identifier: "Banks",
        title: "Banks",
        path: "/settings/banks",
        icon: <FontAwesomeIcon className="me-2" width="20px" height="20px" icon={faUniversity} />,
        activeIcon: <FontAwesomeIcon className="me-2" icon={faUniversity} style={{ padding: "0 3px" }} />,
        Component: BankList,
      },
      {
        subnavigation: true,
        alwaysGenerateRoute: true,
        navigation: false,
        identifier: "plans",
        title: "plans",
        path: "/settings/plans",
        icon: <FontAwesomeIcon className="me-2" width="20px" height="20px" icon={faUniversity} />,
        activeIcon: <FontAwesomeIcon className="me-2" icon={faUniversity} style={{ padding: "0 3px" }} />,
        Component: PlansCards,
      },
      // {
      //   subnavigation: true,
      //   identifier: "Custmization",
      //   title: "Custmization",
      //   path: "/settings/customize",
      //   icon: (
      //     <FontAwesomeIcon
      //       className="me-2"
      //       width="20px"
      //       height="20px"
      //       icon={faPenClip}
      //     />
      //   ),
      //   activeIcon: (
      //     <FontAwesomeIcon
      //       className="me-2"
      //       icon={faPenClip}
      //       style={{ padding: "0 3px" }}
      //     />
      //   ),
      // },
      {
        subnavigation: true,
        alwaysGenerateRoute: true,
        identifier: "Subscriptions",
        title: "Subscriptions",
        path: "/settings/subscriptions",
        icon: <img className="me-2" width="20px" height="20px" src={star} alt="star" />,
        activeIcon: <img className="me-2" width="20px" height="20px" src={activeStar} alt="star" />,
        Component: Subscriptions,
      },
      {
        subnavigation: true,
        alwaysGenerateRoute: true,
        identifier: "SystemSettings",
        title: "System Settings",
        path: "/settings/systemSetting",
        icon: <FontAwesomeIcon className="me-2" icon={faGear} />,
        activeIcon: <FontAwesomeIcon className="me-2" icon={faGear} />,
        Component: SystemSetting,
      },
    ],
  },
];
export default SideNavData;
