// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listingHeader_pageTitle__hv3GN{
    color: #39414A;
    font-family: Cabin;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px; /* 165% */
}

.listingHeader_searchInputControl__yKTo0{
    padding: 0.375rem 0 0.375rem 2.5rem  !important;
    font-family: sans-serif;
    width: 280px;
}


.listingHeader_searchIcon__qHSzy{
    position: absolute;
    top: 7px;
    left: 7px;  
}
.listingHeader_ShowingSelect__LCh1C{
    width: 200px;
    border-radius: 6px;
}
.listingHeader_iconBorder__qLzWW {
    padding: 6px;
    border: 1px solid;
    border-radius: 6px;
  }

.listingHeader_searchIconMobile__vSEfb {
    color: #9537ff;
  }

  .listingHeader_addButtonLink__CfDBD{
    min-width: 140px;
  }

  .listingHeader_filtersContainer__IY2cL{
    background-color: white;
  }

  .listingHeader_filtersContainerBottom__LeiE9{
    border-bottom: 1px solid #DEDFE4;
  }

@media screen and (max-width: 1200px) {
    .listingHeader_pageTitle__hv3GN{
        font-size: 24px;
        font-weight: 500;
        line-height: normal;
    }
    .listingHeader_filtersContainer__IY2cL{
        background-color: transparent;
      }
      
  .listingHeader_filtersContainerBottom__LeiE9{
    border: 0;
  }
    
}

@media screen and (max-width: 575px) {
  .listingHeader_addButtonLink__CfDBD{
    min-width: 0px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Listings/ListingHeader/listingHeader.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB,EAAE,SAAS;AAChC;;AAEA;IACI,+CAA+C;IAC/C,uBAAuB;IACvB,YAAY;AAChB;;;AAGA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;AACb;AACA;IACI,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;EACpB;;AAEF;IACI,cAAc;EAChB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,gCAAgC;EAClC;;AAEF;IACI;QACI,eAAe;QACf,gBAAgB;QAChB,mBAAmB;IACvB;IACA;QACI,6BAA6B;MAC/B;;EAEJ;IACE,SAAS;EACX;;AAEF;;AAEA;EACE;IACE,cAAc;EAChB;AACF","sourcesContent":[".pageTitle{\n    color: #39414A;\n    font-family: Cabin;\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 33px; /* 165% */\n}\n\n.searchInputControl{\n    padding: 0.375rem 0 0.375rem 2.5rem  !important;\n    font-family: sans-serif;\n    width: 280px;\n}\n\n\n.searchIcon{\n    position: absolute;\n    top: 7px;\n    left: 7px;  \n}\n.ShowingSelect{\n    width: 200px;\n    border-radius: 6px;\n}\n.iconBorder {\n    padding: 6px;\n    border: 1px solid;\n    border-radius: 6px;\n  }\n\n.searchIconMobile {\n    color: #9537ff;\n  }\n\n  .addButtonLink{\n    min-width: 140px;\n  }\n\n  .filtersContainer{\n    background-color: white;\n  }\n\n  .filtersContainerBottom{\n    border-bottom: 1px solid #DEDFE4;\n  }\n\n@media screen and (max-width: 1200px) {\n    .pageTitle{\n        font-size: 24px;\n        font-weight: 500;\n        line-height: normal;\n    }\n    .filtersContainer{\n        background-color: transparent;\n      }\n      \n  .filtersContainerBottom{\n    border: 0;\n  }\n    \n}\n\n@media screen and (max-width: 575px) {\n  .addButtonLink{\n    min-width: 0px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageTitle": `listingHeader_pageTitle__hv3GN`,
	"searchInputControl": `listingHeader_searchInputControl__yKTo0`,
	"searchIcon": `listingHeader_searchIcon__qHSzy`,
	"ShowingSelect": `listingHeader_ShowingSelect__LCh1C`,
	"iconBorder": `listingHeader_iconBorder__qLzWW`,
	"searchIconMobile": `listingHeader_searchIconMobile__vSEfb`,
	"addButtonLink": `listingHeader_addButtonLink__CfDBD`,
	"filtersContainer": `listingHeader_filtersContainer__IY2cL`,
	"filtersContainerBottom": `listingHeader_filtersContainerBottom__LeiE9`
};
export default ___CSS_LOADER_EXPORT___;
